<template>
    <v-form
        :key="`profileChangePasswordForm-${componentKey}`"
        v-model="isValid"
        ref="authChangePasswordForm"
    >
        <component
            v-for="field in fields"
            :key="field.name"
            :is="field.component"
            v-model="formData[field.name]"
            v-bind="field.props"
            :error-messages="formErrors[field.name]"
            @click:append="onShowHidePassword(field.name)"
        />
        <div
            class="d-flex align-center"
        >
            <v-spacer></v-spacer>
            <v-btn
                :disabled="!isValid"
                color="success"
                depressed
                @click="onSubmit"
            >
                {{ $trans('Update') }}
            </v-btn>
        </div>
    </v-form>
</template>

<script>
import { confirmedValidator, passwordValidator, required } from '@/utils/validation'
import { changeAuthPassword } from '@apps/auth/api'

export default {
    name: 'AuthChangePassword',
    components: {
        'v-text-field': () => import('vuetify/lib/components/VTextField')
    },
    data() {
        return {
            componentKey: 0,
            isValid: true,
            formData: {},
            formErrors: {},
            fieldStates: {
                old_password: {
                    type: 'password',
                    icon: 'mdi-eye-off'
                },
                password: {
                    type: 'password',
                    icon: 'mdi-eye-off'
                },
                password_confirmation: {
                    type: 'password',
                    icon: 'mdi-eye-off'
                }
            },
            rules: {
                password: [
                    required,
                    passwordValidator
                ],
                password_confirmation: [
                    required,
                    v => confirmedValidator(v, this.formData.password)
                ]
            }
        }
    },
    computed: {
        action() {
            return {
                text: 'Update',
                action: 'update'
            }
        },
        fields() {
            return [
                {
                    component: 'v-text-field',
                    name: 'old_password',
                    props: {
                        label: this.$trans('Old password'),
                        outlined: true,
                        dense: true,
                        rules: [ required ],
                        type: this.fieldStates.old_password.type,
                        appendIcon: this.fieldStates.old_password.icon
                    }
                },
                {
                    component: 'v-text-field',
                    name: 'password',
                    props: {
                        label: this.$trans('New password'),
                        outlined: true,
                        dense: true,
                        rules: this.rules.password,
                        type: this.fieldStates.password.type,
                        appendIcon: this.fieldStates.password.icon
                    }
                },
                {
                    component: 'v-text-field',
                    name: 'password_confirmation',
                    props: {
                        label: this.$trans('Password confirmation'),
                        outlined: true,
                        dense: true,
                        rules: this.rules.password_confirmation,
                        type: this.fieldStates.password_confirmation.type,
                        appendIcon: this.fieldStates.password_confirmation.icon
                    }
                }
            ]
        }
    },
    methods: {
        onShowHidePassword(fieldName) {
            this.fieldStates[fieldName].type = this.fieldStates[fieldName].type === 'password'
                ? 'text'
                : 'password'

            this.fieldStates[fieldName].icon = this.fieldStates[fieldName].type === 'password'
                ? 'mdi-eye-off'
                : 'mdi-eye'
        },
        async onSubmit() {
            if(!this.$refs.authChangePasswordForm.validate()) {
                return
            }

            this.$lprogress.begin()

            try {
                await changeAuthPassword(this.formData)
                await this.$store.dispatch('auth/logout')
                await this.$router.push({ name: 'auth.login' })
            } catch (error) {
                if(error.response &&
                    error.response.data &&
                    error.response.data.errors
                ) {
                    this.formErrors = Object.assign({}, error.response.data.errors)
                }
            } finally {
                this.$lprogress.end()
            }
        }
    }
}
</script>

<style lang=scss>

</style>
