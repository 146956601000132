<template>
    <content-wrapper
        :breadcrumbs="breadcrumbs"
        comeback-previous
        max-width="680"
        min-height="100vh"
    >
        <settings-form
            :item="localeItem"
            :fields="localeFields"
            @change:locale="onChangeLanguage"
        />
        <settings-form
            :item="themeItem"
            :fields="themeFields"
            @change:activeTheme="onChangeActiveTheme"
            class="mt-4"
        />
        <v-card
            class="mt-4"
        >
            <settings-list-item
                v-bind="changePassword"
                class="py-1"
            />
        </v-card>
        <w-dialog-drawer
            v-model="changePasswordDialog"
            :title="$trans('Change password')"
            :icon="changePasswordIcon"
        >
            <v-card
                class="pa-4"
            >
                <auth-change-password/>
            </v-card>
        </w-dialog-drawer>
    </content-wrapper>
</template>

<script>
import ContentWrapper from '@/components/ContentWrapper'
import { PASSWORD, TRANSLATE } from '@/widgets/components/WIcon/icons'
import SettingsForm from '@apps/settings/components/SettingsForm'
import EventEmitter from '@/plugins/EventEmitter'
import AuthChangePassword from '@apps/auth/components/AuthChangePassword'
import SettingsListItem from '@apps/settings/components/SetingsList/SettingsListItem'

export default {
    name: 'AuthSettings',
    components: { SettingsListItem, AuthChangePassword, SettingsForm, ContentWrapper },
    computed: {
        breadcrumbs() {
            return [
                {
                    text: this.$trans('Profile'),
                    to: {name: 'auth.profile'}
                },
                {
                    text: this.$trans('Settings')
                }
            ]
        },
        appLanguages() {
            return this.$store.getters.languages
        },
        localeItem() {
            return {
                locale: this.$store.getters.locale
            }
        },
        localeFields() {
            const items = []

            this.appLanguages.forEach(o => {
                items.push({
                    text: this.$trans(o.locale),
                    value: o.locale
                })
            })

            return [
                {
                    icon: TRANSLATE,
                    label: 'Language of UI',
                    type: 'v-select',
                    name: 'locale',
                    props: {
                        outlined: true,
                        hideDetails: true,
                        dense: true,
                        items: items
                    }
                }
            ]
        },
        themeItem() {
            return {
                activeTheme: localStorage.getItem('materio-active-theme') === null
                    ? 'light'
                    : localStorage.getItem('materio-active-theme')
            }
        },
        themeFields() {
            const items = [
                {
                    text: 'Light',
                    value: 'light'
                },
                {
                    text: 'Dark',
                    value: 'dark'
                }
            ]

            return [
                {
                    icon: 'mdi-theme-light-dark',
                    label: 'Theme',
                    type: 'v-select',
                    name: 'activeTheme',
                    props: {
                        outlined: true,
                        hideDetails: true,
                        dense: true,
                        items: items
                    }
                }
            ]
        },
        changePasswordIcon() {
            return PASSWORD
        },
        changePassword() {
            return {
                icon: this.changePasswordIcon,
                title: 'Change password',
                color: 'primary',
                on: {
                    click: () => {
                        this.changePasswordDialog = true
                    }
                }
            }
        }
    },
    data() {
        return {
            changePasswordDialog: false
        }
    },
    methods: {
        onChangeLanguage(locale) {
            EventEmitter.emit('change:locale', locale)
        },
        async onChangeActiveTheme(activeTheme) {
            this.$vuetify.theme.dark = activeTheme === 'dark'
            localStorage.setItem('materio-active-theme', activeTheme)
        }
    }
}
</script>

<style lang=scss>

</style>
